<template>
  <div class="v-disconts-page" id="prices">
      <CardGallery/>
    <div class="text-container">
      <div class="zagolovok" id="zagolovok">Номера</div>
    </div>
    <div class="help-background">
      <div class="help" style="padding-top: 10px; font-style: normal;">
        <div class="rules-position">
          <div class="rules dop-price-page">•	Сауна <b>«Уют»</b> стоимость одного часа <b>1 600 руб.</b> вместимость 4 человека, минимальный заказ 2 часа. </div>
          <div class="rules dop-price-page">•	Сауна <b>«Комфорт»</b> стоимость одного часа <b>1 800 руб.</b> вместимость 4 человека, минимальный заказ 2 часа.  </div>
          <div class="rules dop-price-page">•	Сауна <b>«Эрчим»</b> стоимость одного часа <b>2 000 руб.</b> вместимость 6 человек, минимальный заказ 2 часа. </div>
          <div class="rules dop-price-page">•	Сауна <b>«Русский дом»</b> стоимость одного часа <b>2 800 руб.</b> вместимость 8 человек, минимальный заказ 2 часа.  </div>
          <div class="rules dop-price-page">•	Сауна <b>«Берлога»</b> стоимость одного часа <b>2 900 руб.</b> вместимость 8 человек, минимальный заказ 2 часа. </div>
          <div class="rules dop-price-page">•	Сауна <b>«Семейный»</b> стоимость одного часа <b>2 400 руб.</b> вместимость 6 человек, минимальный заказ 2 часа.  </div>
          <div class="rules dop-price-page">• Сауна <b>«VIP»</b> стоимость одного часа <b>3 000 руб.</b> вместимость 8 человек, минимальный заказ 2 часа.  </div>
          <div class="rules dop-price-page">•	<b>«Общее отделении»</b> стоимость одного часа <b>4 500 руб.</b> вместимость 15 человек, минимальный заказ 2 часа. </div>
          <div class="rules dop-price-page">•	<b>«Общее отделении с каминным залом»</b> стоимость одного часа <b>5 000 руб.</b> вместимость 15 человек, минимальный заказ 2 часа.</div>
          <div class="rules dop-price-page">•	<b>«Каминный зал»</b> стоимость одного часа <b>1 500 руб.</b> вместимость 8 человек.</div>
          <div class="rules dop-price-page">Оплата за каждого дополнительного человека составляет 200 рублей 1 час.</div>
        </div>
      </div>
    </div>
  <CommonGallery/>
  <div class="text-container">
      <div class="zagolovok" id="zagolovok">Общественное отделение</div>
  </div>
  <div class="help-background">
      <div class="help" style="padding-top: 10px; font-style: normal;">
        <div class="rules-position">
            <div class="rules dop-price-page" style="font-size: 20px; font-weight: bold;">Пятница: </div>
            <div class="rules dop-price-page" style="text-indent: 3em;">Время работы: с 15.00 до 21.00 (время сеанса 2 часа 30 минут)</div>
            <div class="rules dop-price-page" style="text-indent: 3em;">Стоимость билета: взрослые 700 рублей, дети до 12 лет 350 рублей, дети до 7 лет бесплатно.</div>
            <div class="rules dop-price-page" style="font-size: 20px; font-weight: bold;">Суббота, воскресенье: </div>
            <div class="rules dop-price-page" style="text-indent: 3em;">Время работы: с 11.00 до 21.00 (время сеанса 2 часа 30 минут)</div>
            <div class="rules dop-price-page" style="text-indent: 3em;">Стоимость билета: взрослые 850 рублей, дети до 12 лет 400 рублей, дети до 7 лет бесплатно. </div>
            <div class="rules dop-price-page" style="text-indent: 3em;">Стоимость билета с каминным залом (2 часа 30 минут) 1200 рублей, дети до 12 лет 600 рублей, дети до 7 лет бесплатно.</div>
            <div class="rules dop-price-page" style="text-indent: 3em; padding-top: 20px;">Дополнительное время нахождения в общем отделении оплачивается в соотношении 1 минута - 7 рублей (420 рублей час). </div>
            <div class="rules dop-price-page" style="text-indent: 3em;">Безлимитное пребывание в общем отделении 1800 рублей.</div>
            <div class="rules dop-price-page" style="text-indent: 3em;">Аренда во время сеанса общего отделения «Каминного зала» 3500 рублей/час, вместимость 8 человек.</div>
        </div>
      </div>
  </div>
  </div>  
</template>

<script>
import CardGallery from '@/components/v-card-gallery'
import CommonGallery from '@/components/v-common-gallery'
export default {
  name: 'price-page',
  components: {
    CardGallery,
    CommonGallery
  }
}
</script>

<style>
.v-price-page{
  border: 5px solid #121820;
}
.dop-price-page{
  overflow: hidden;
  padding-right: 20px;
}
</style>
